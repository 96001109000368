import axios from "axios";

export default {
    isPending,
    createFirstUser
};

export async function isPending() {
    return await axios.get("/api/Setup/IsPending");
}

export async function createFirstUser(name, emailAddress, password, timeZoneId, cultureId) {
    await axios.post("/api/Setup/CreateFirstUser", {
        person: { name }, 
        emailAddress, 
        password, 
        timeZoneId, 
        cultureId });
}