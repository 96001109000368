<template>
    <v-container fill-height fluid>
        <v-row v-if="!loading" align="center" justify="center">
            <v-col cols="12" sm="8" md="3">
                <v-card class="elevation-5 pa-0">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>Create the first user</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form
                        ref="form"
                        v-model="valid"
                        @submit.prevent="submit">
                        <v-card-text class="pb-0 mt-2">
                            <v-text-field
                                v-model="name"
                                outlined
                                label="Name"
                                type="text"
                                :rules="[v => !!v || 'Name is required']"
                                required 
                                autofocus />
                            <v-text-field
                                v-model="emailAddress"
                                outlined
                                label="Email Address"
                                type="text"
                                :rules="emailAddressRules"
                                required />
                            <v-text-field
                                v-model="password"
                                outlined
                                label="Password"
                                type="password"
                                :rules="[v => !!v || 'Password is required']"
                                required />
                            <app-autocomplete
                                v-model="timeZoneId"
                                label="Time Zone"
                                :items="timeZones"
                                required 
                                :rules="[v => !!v || 'Time zone is required']" />
                            <app-autocomplete
                                v-model="cultureId"
                                label="Culture"
                                :items="cultures"
                                required
                                :rules="[v => !!v || 'Culture is required']" />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <v-spacer />
                            <v-btn
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                type="submit"
                                :disabled="loading"
                                :loading="loading">
                                Create and log in
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isPending, createFirstUser } from '@/features/account/services/setupService';
import { idLabels } from "@/features/schemas/services/schemaApi";
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            loading: true,
            valid: true,

            cultures: [],
            timeZones: [],

            name: "",
            password: "",
            emailAddress: "",
            timeZoneId: null,
            cultureId: null,

            emailAddressRules: [
                v => !!v || "Email Address is required",
                v =>
                    !(v && v.length > 320) ||
                    "Email Address must be less than 320 characters",
            ],
        };
    },
    async mounted() {
        if(!(await isPending())) {
            this.$router.push("/");
            return;
        }

        try {
            this.cultures = await idLabels("culture", { sortBy: "name" });
            this.timeZones = await idLabels("timeZone", { sortBy: "name" });
        }
        finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            refreshUser: "authentication/refreshUser"
        }),

        async submit() {
            this.$refs.form.validate();
            if (!this.valid) {
                return;
            }

            await createFirstUser(
                this.name, 
                this.emailAddress, 
                this.password, 
                this.timeZoneId, 
                this.cultureId);

            await this.refreshUser();
            this.$router.push("/");
        }
    }
};
</script>
